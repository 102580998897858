import _axios from '@/api/axios'

export default {
    //详情页
    goodsDetail(data) {
        return _axios({
            url: '/huarenApi/pc/product/detail',
            method: 'post',
            data
        })
    },
    //收藏
    collect(data) {
        return _axios({
            url: '/huarenApi/pc/product/doFav',
            method: 'post',
            data
        })
    },
    //留言
    saveMessage(data) {
        return _axios({
            url: '/huarenApi/pc/product/proposal',
            method: 'post',
            data
        })
    },
    //推荐列表
    recommendList(data) {
        return _axios({
            url: '/huarenApi/pc/detail/recommend',
            method: 'post',
            data
        })
    },
    ///加入购物车
    addCart(data) {
        return _axios({
            url: '/huarenApi/pc/joinCar',
            method: 'post',
            data
        })
    },
    //获取购物车
    getCart(data) {
        return _axios({
            url: '/huarenApi/pc/returnCar',
            method: 'post',
            data
        })
    },
    //删除
    deleteCart(data) {
        return _axios({
            url: '/huarenApi/pc/deleteCar',
            method: 'post',
            data
        })
    },
    //选中
    selectCart(data) {
        return _axios({
            url: '/huarenApi/pc/checkedCar',
            method: 'post',
            data
        })
    },
    //数量
    changeNum(data) {
        return _axios({
            url: '/huarenApi/pc/editCar',
            method: 'post',
            data
        })
    },
    //收货地址
    getAddress(data) {
        return _axios({
            url: '/huarenApi/pc/buyer/address',
            method: 'post',
            data
        })
    },
    //订单信息
    getOrder(data) {
        return _axios({
            url: '/huarenApi/pc/submitCart',
            method: 'post',
            data
        })
    },
    //发票
    getInvoice(data) {
        return _axios({
            url: '/huarenApi/pc/invoiceOption',
            method: 'post',
            data
        })
    },
    //提交订单
    submitOrder(data) {
        return _axios({
            url: '/huarenApi/pc/submitOrder',
            method: 'post',
            data
        })
    },
    //支付方式
    getPayOption(data,responseType="") {
        return _axios({
            url: '/huarenApi/pc/pay/payment',
            method: 'post',
            data,
            responseType
        })
    },
    //支付结果
    getPayResult(data) {
        return _axios({
            url: '/huarenApi/pc/pay/query',
            method: 'post',
            data
        })
    },
    //订单支付详情
    getPayDetail(data) {
        return _axios({
            url: '/huarenApi/pc/order/detail',
            method: 'post',
            data
        })
    },
    //下载首营资料
    downloadLicense(data) {
        return _axios({
            url: '/huarenApi/pc/product/license',
            method: 'post',
            data,
             responseType: 'blob'
        })
    },
}