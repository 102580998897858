/*
 * 入口文件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-04 23:38:41
 */
import Vue from 'vue'
import App from './App.vue'
import './api/axios'
import router from './router'
import store from './store'
import filters from './utils/price'

// import './permission'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
import ToastComponent from './components/Toast.vue';
// 引入Loading组件
import { Loading } from 'element-ui';

import vueEsign from 'vue-esign'
Vue.use(vueEsign)
Vue.use(ElementUI);
Vue.prototype.$loading = Loading.service;

// 全局消息提示
const ToastConstructor = Vue.extend(ToastComponent);
let toastInstance;
Vue.prototype.$toast = (message) => {
  if (!toastInstance) {
    toastInstance = new ToastConstructor({
      el: document.createElement('div'),
      propsData: { message }
    });
    document.body.appendChild(toastInstance.$el);
  }
  toastInstance.show(message);
};

// 添加全局方法-处理价格展示样式
Vue.prototype.initPrice=filters['initPrice']
// const baseURL= 'http://b2bmall.fastjz.com:299'//测试环境
const baseURL= 'https://b2bmall.hrjkjt.com'//正式环境
// 添加全局方法-处理价格展示样式
Vue.prototype.baseURL=baseURL
// 引入axios请求接口
import api from './api/index'
Vue.use(api)


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
